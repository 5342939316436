import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import styles from './MainLayout.module.scss';
import { useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';
import { routes } from '../../../config/Router/routes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../Button/Button';
import logo from '../../../assets/citywave-logo.svg';
import Footer from './Footer/Footer';
import useWindowSize from '../../../hooks/useWindowSize/useWindowSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTimes,
  faArrowRight,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconButton, Popover } from '@mui/material';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { User } from '../../../domain/User';
import couponIcon from '../../../assets/icons/coupon.svg';
import subscriptionsIcon from '../../../assets/icons/subscriptions.svg';
import AuthCard from './AuthCard/AuthCard';
import { getRemainingWordForSessions } from '../../../utility/session/remaining-word';
import phoneIcon from '../../../assets/icons/phone.svg';
import profileIcon from '../../../assets/icons/profile.svg';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { logout } from '../../../store/auth/actions';
// eslint-disable-next-line max-len
import NewsletterSubscribeModal from '../../../component/Public/NewsletterSubscribe/NewsletterSubscribeModal/NewsletterSubscribeModal';
import moment from 'moment';
import LanguageSwitcher from '../../../common/LanguageSwitcher/LanguageSwitcher';

type Props = {
  children: ReactNode;
  currentUser: User | null;
  onLogout: () => void;
  isUpdateNeeded: boolean;
};

type NavigationItem = {
  label: string;
  to?: string;
  scrollTo?: string;
};

export enum NavigationScroll {
  // eslint-disable-next-line no-unused-vars
  ABOUT_US = 'homepage-about-us',
  // eslint-disable-next-line no-unused-vars
  SESSION_INFO = 'session-info',
  // eslint-disable-next-line no-unused-vars
  FAQ = 'homepage-faq',
  // eslint-disable-next-line no-unused-vars
  CONTACT_US = 'homepage-contact-us',
  // eslint-disable-next-line no-unused-vars
  SUBSCRIPTIONS = 'subscriptions',
  // eslint-disable-next-line no-unused-vars
  COUPONS = 'coupons',
  // eslint-disable-next-line no-unused-vars
  PROFILE = 'profile',
}

const MOBILE_BREAK_POINT = 1350;

export const MainLayout = ({
  children,
  currentUser,
  onLogout,
  isUpdateNeeded,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProfileViewOpen, setIsProfileViewOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [isNewsletterSubscribeModalOpen, setIsNewsletterSubscribeModalOpen] =
    useState<boolean>(false);

  const location = useLocation();

  const { width } = useWindowSize();

  useEffect(() => {
    if (location.pathname === routes.newsletter) {
      setIsNewsletterSubscribeModalOpen(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (width && width > MOBILE_BREAK_POINT) {
      setIsMobileMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const isNewsletterSubscribed = localStorage.getItem(
      'isNewsletterSubscribed',
    );

    const newsletterSubscribeModalClosedAt = localStorage.getItem(
      'newsletterSubscribeModalClosedAt',
    );

    const lastAction = moment(newsletterSubscribeModalClosedAt);

    const difference = moment.duration(moment().diff(lastAction));

    if (
      isNewsletterSubscribed ||
      location.pathname !== routes.homepage ||
      difference.asHours() < 24
    ) {
      return;
    }

    const timeout = setTimeout(
      () => setIsNewsletterSubscribeModalOpen(true),
      15000,
    );

    return () => clearTimeout(timeout);
  });

  const handleNewsletterSubscribeModalClose = () => {
    localStorage.setItem(
      'newsletterSubscribeModalClosedAt',
      moment().toString(),
    );

    setIsNewsletterSubscribeModalOpen(false);

    if (location.pathname === routes.newsletter) {
      navigate(routes.homepage, { replace: true });
    }
  };

  const profileNavigationItems: NavigationItem[] = useMemo(() => {
    if (!currentUser) {
      return [];
    }

    return [
      {
        label: translate(intl, 'TOP_NAVIGATION.PROFILE_OPTIONS'),
        to: routes.profile,
      },
      {
        label: translate(intl, 'TOP_NAVIGATION.PROFILE_RESERVATIONS'),
        to: routes.myReservations,
      },
      {
        label: translate(intl, 'TOP_NAVIGATION.PROFILE_PAYMENTS'),
        to: routes.paymentsHistory,
      },
      {
        label: translate(intl, 'TOP_NAVIGATION.PROFILE_SUBSCRIPTIONS'),
        to: routes.mySubscriptions,
      },
      {
        label: translate(intl, 'TOP_NAVIGATION.PROFILE_COUPONS'),
        to: routes.myCoupons,
      },
    ];
  }, [currentUser]);

  const topNavigationItems: NavigationItem[] = useMemo(() => {
    return [
      currentUser &&
        isMobileMenuOpen && {
          label: translate(intl, 'TOP_NAVIGATION.PROFILE'),
          scrollTo: NavigationScroll.PROFILE,
        },
      {
        label: translate(intl, 'TOP_NAVIGATION.ABOUT_US'),
        scrollTo: NavigationScroll.ABOUT_US,
      },
      {
        label: translate(intl, 'TOP_NAVIGATION.SESSION_INFO'),
        scrollTo: NavigationScroll.SESSION_INFO,
      },
      {
        label: translate(intl, 'TOP_NAVIGATION.FAQ'),
        scrollTo: NavigationScroll.FAQ,
      },
      {
        label: translate(intl, 'TOP_NAVIGATION.CONTACT_US'),
        scrollTo: NavigationScroll.CONTACT_US,
      },
      {
        label: translate(intl, 'TOP_NAVIGATION.SUBSCRIPTIONS'),
        scrollTo: NavigationScroll.SUBSCRIPTIONS,
      },
      {
        label: translate(intl, 'TOP_NAVIGATION.COUPONS'),
        scrollTo: NavigationScroll.COUPONS,
      },
    ].filter((item) => !!item) as NavigationItem[];
  }, [currentUser, isMobileMenuOpen, isUpdateNeeded]);

  const onNavigationItemClick = (scrollToId?: string) => {
    if (!scrollToId) return;

    if (scrollToId === NavigationScroll.SUBSCRIPTIONS) {
      navigate(routes.subscriptions);

      return;
    }

    if (scrollToId === NavigationScroll.COUPONS) {
      navigate(routes.publicCoupons);

      return;
    }

    if (scrollToId === NavigationScroll.PROFILE) {
      navigate(routes.profile);

      return;
    }

    if (location.pathname !== routes.homepage) {
      localStorage.setItem('scrollToId', scrollToId);
      navigate(routes.homepage);

      return;
    }

    const section = document.querySelector(`#${scrollToId}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setIsMobileMenuOpen(false);
    }
  };

  const topNavigationButtons = useMemo(() => {
    if (currentUser) {
      return (
        <>
          <Button onClick={() => navigate(routes.reservation)}>
            {translate(intl, 'TOP_NAVIGATION.RESERVE_TIME')}
          </Button>
          <LanguageSwitcher />
          <div className={styles.authIcons}>
            <a href={translate(intl, 'FOOTER_CONTACT.PHONE_LINK')}>
              <img src={phoneIcon} />
            </a>
            <img
              src={profileIcon}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setIsPopoverOpen(true);
              }}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <Button color={'secondary'} onClick={() => navigate(routes.login)}>
          {translate(intl, 'TOP_NAVIGATION.LOGIN')}
        </Button>
        <Button onClick={() => navigate(routes.reservation)}>
          {translate(intl, 'TOP_NAVIGATION.RESERVE_TIME')}
        </Button>
        <LanguageSwitcher />
      </>
    );
  }, [currentUser]);

  return (
    <div className={styles.mainLayout}>
      <header className={styles.header}>
        <nav className={styles.headerInner}>
          <div className={styles.logoContainer}>
            <img
              src={logo}
              alt="CityWave"
              onClick={() => navigate(routes.homepage)}
            />
          </div>
          {width && width >= MOBILE_BREAK_POINT ? (
            <div className={styles.navigationItems}>
              <div className={styles.topLinks}>
                {topNavigationItems.map((navigationItem, index) => (
                  <a
                    onClick={() =>
                      onNavigationItemClick(navigationItem?.scrollTo ?? '')
                    }
                    key={`top-navigation-${index}`}
                  >
                    {navigationItem.label}
                  </a>
                ))}
              </div>
              <div className={styles.topButtons}>{topNavigationButtons}</div>
            </div>
          ) : (
            <div className={styles.mobileNavigationButtons}>
              <Button
                onClick={() => navigate(routes.reservation)}
                className={styles.reserveButton}
              >
                {translate(intl, 'TOP_NAVIGATION.RESERVE_TIME')}
              </Button>
              <LanguageSwitcher />

              <IconButton
                onClick={() => setIsMobileMenuOpen((prev) => !prev)}
                className={styles.iconContainer}
              >
                <FontAwesomeIcon
                  icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                  fixedWidth
                  size="sm"
                />
              </IconButton>
            </div>
          )}
        </nav>
        {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
          <nav className={styles.drawerContainer}>
            {isProfileViewOpen && (
              <a
                href={'#'}
                className={styles.drawerNavigationBack}
                onClick={() => setIsProfileViewOpen(false)}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft as IconProp}
                  fixedWidth
                  size="sm"
                />
                {translate(intl, 'TOP_NAVIGATION.BACK')}
              </a>
            )}
            {(isProfileViewOpen
              ? profileNavigationItems
              : topNavigationItems
            ).map((navigationItem, index) => {
              if (navigationItem.to === routes.profile && !isProfileViewOpen) {
                return (
                  <a
                    key={`top-navigation-${index}`}
                    className={styles.drawerNavigationItem}
                    href={'#'}
                    onClick={() => setIsProfileViewOpen(true)}
                  >
                    {navigationItem.label}
                    <FontAwesomeIcon
                      icon={faArrowRight as IconProp}
                      fixedWidth
                      size="sm"
                    />
                  </a>
                );
              }

              return (
                <a
                  onClick={() =>
                    onNavigationItemClick(navigationItem?.scrollTo)
                  }
                  key={`top-navigation-${index}`}
                  className={styles.drawerNavigationItem}
                >
                  {navigationItem.label}
                  <FontAwesomeIcon
                    icon={faArrowRight as IconProp}
                    fixedWidth
                    size="sm"
                  />
                </a>
              );
            })}
            {!isProfileViewOpen && currentUser && (
              <a
                className={styles.drawerNavigationItem}
                href={'#'}
                onClick={() => onLogout()}
              >
                {translate(intl, 'TOP_NAVIGATION.LOGOUT')}
                <FontAwesomeIcon
                  icon={faArrowRight as IconProp}
                  fixedWidth
                  size="sm"
                />
              </a>
            )}

            <div className={styles.drawerButton}>
              {currentUser ? (
                <div className={styles.authDrawerItems}>
                  <AuthCard
                    icon={couponIcon}
                    title={`${currentUser.couponsCredit}€`}
                    subTitle={translate(
                      intl,
                      'TOP_NAVIGATION.REMAINING_COUPON',
                    )}
                  />
                  <AuthCard
                    icon={subscriptionsIcon}
                    title={getRemainingWordForSessions(
                      currentUser.remainingSessions,
                      intl,
                    )}
                    subTitle={translate(
                      intl,
                      'TOP_NAVIGATION.REMAINING_SUBSCRIPTIONS',
                    )}
                  />
                </div>
              ) : (
                <Button
                  color={'secondary'}
                  onClick={() => navigate(routes.login)}
                >
                  {translate(intl, 'TOP_NAVIGATION.LOGIN')}
                </Button>
              )}
            </div>
          </nav>
        )}
      </header>
      <Popover
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={styles.profilePopover}
      >
        <div className={styles.popoverContent}>
          <ul className={styles.popoverList}>
            {profileNavigationItems.map((navigationItem, index) => {
              return (
                <Link
                  to={navigationItem?.to ?? ''}
                  key={`top-navigation-${index}`}
                  replace
                  className={styles.popoverNavigationItem}
                >
                  {navigationItem.label}
                  <FontAwesomeIcon
                    icon={faArrowRight as IconProp}
                    fixedWidth
                    size="sm"
                  />
                </Link>
              );
            })}
            {currentUser && (
              <a
                href={'#'}
                className={styles.popoverNavigationItem}
                onClick={() => onLogout()}
              >
                {translate(intl, 'TOP_NAVIGATION.LOGOUT')}
                <FontAwesomeIcon
                  icon={faArrowRight as IconProp}
                  fixedWidth
                  size="sm"
                />
              </a>
            )}
          </ul>
          {currentUser && (
            <div className={styles.popoverAuthActions}>
              <AuthCard
                icon={couponIcon}
                title={`${currentUser.couponsCredit}€`}
                subTitle={translate(intl, 'TOP_NAVIGATION.REMAINING_COUPON')}
              />
              <AuthCard
                icon={subscriptionsIcon}
                title={getRemainingWordForSessions(
                  currentUser.remainingSessions,
                  intl,
                )}
                subTitle={translate(
                  intl,
                  'TOP_NAVIGATION.REMAINING_SUBSCRIPTIONS',
                )}
              />
            </div>
          )}
        </div>
      </Popover>
      <main className={styles.content}>{children}</main>
      <Footer />
      <NewsletterSubscribeModal
        isOpen={isNewsletterSubscribeModalOpen}
        onClose={handleNewsletterSubscribeModalClose}
      />
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
  isUpdateNeeded: state.language.languageSuccess,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
