import {
  FbPixelEventName,
  SendFbPixelEvent,
} from '../../store/fb-pixel/service';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

export const sendFbPixelEvent = (
  event: string,
  eventId: string,
  amount?: string | number | null,
) => {
  // @ts-ignore
  if (typeof fbq === 'undefined') {
    return;
  }

  if (amount) {
    // @ts-ignore
    fbq?.('track', 'Purchase', {
      value: amount,
      currency: 'EUR',
      eventId: eventId,
    });
    return;
  }

  // @ts-ignore
  fbq?.('track', event, { eventId: eventId });
};

export const sendAllFbPixelEvents = (
  onSendFbPixelEvent: (data: SendFbPixelEvent, isAuthorized: boolean) => void,
  event: FbPixelEventName,
  isAuthorized: boolean,
  amount?: string | number,
) => {
  const eventId = uuidv4();

  sendFbPixelEvent(event, eventId, amount);

  onSendFbPixelEvent(
    {
      eventName: event,
      fbc: Cookies.get('_fbc') ?? undefined,
      eventId: eventId,
      purchaseValue: amount ?? undefined,
    },
    isAuthorized,
  );
};
