export const FETCH_PAYMENT_LIST_START = 'FETCH_PAYMENT_LIST_START';
export const FETCH_PAYMENT_LIST_SUCCESS = 'FETCH_PAYMENT_LIST_SUCCESS';
export const FETCH_PAYMENT_LIST_FAIL = 'FETCH_PAYMENT_LIST_FAIL';

export const FETCH_USER_PAYMENT_LIST_START = 'FETCH_USER_PAYMENT_LIST_START';
export const FETCH_USER_PAYMENT_LIST_SUCCESS =
  'FETCH_USER_PAYMENT_LIST_SUCCESS';
export const FETCH_USER_PAYMENT_LIST_FAIL = 'FETCH_USER_PAYMENT_LIST_FAIL';

export const FETCH_PAYMENT_START = 'FETCH_PAYMENT_START';
export const FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS';
export const FETCH_PAYMENT_FAIL = 'FETCH_PAYMENT_FAIL';

export const CREATE_PAYMENT_START = 'CREATE_PAYMENT_START';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAIL = 'CREATE_PAYMENT_FAIL';

export const CREATE_COUPON_PAYMENT_START = 'CREATE_COUPON_PAYMENT_START';
export const CREATE_COUPON_PAYMENT_SUCCESS = 'CREATE_COUPON_PAYMENT_SUCCESS';
export const CREATE_COUPON_PAYMENT_FAIL = 'CREATE_COUPON_PAYMENT_FAIL';

export const FETCH_USER_PAYMENTS_LIST_START = 'FETCH_USER_PAYMENTS_LIST_START';
export const FETCH_USER_PAYMENTS_LIST_SUCCESS =
  'FETCH_USER_PAYMENTS_LIST_SUCCESS';
export const FETCH_USER_PAYMENTS_LIST_FAIL = 'FETCH_USER_PAYMENTS_LIST_FAIL';

export const FETCH_USER_SUBSCRIPTION_PAYMENTS_LIST_START =
  'FETCH_USER_SUBSCRIPTION_PAYMENTS_LIST_START';
export const FETCH_USER_SUBSCRIPTION_PAYMENTS_LIST_SUCCESS =
  'FETCH_USER_SUBSCRIPTION_PAYMENTS_LIST_SUCCESS';
export const FETCH_USER_SUBSCRIPTION_PAYMENTS_LIST_FAIL =
  'FETCH_USER_SUBSCRIPTION_PAYMENTS_LIST_FAIL';

export const DOWNLOAD_MULTIPLE_PAYMENT_INVOICES_START =
  'DOWNLOAD_MULTIPLE_PAYMENT_INVOICES_START';
export const DOWNLOAD_MULTIPLE_PAYMENT_INVOICES_SUCCESS =
  'DOWNLOAD_MULTIPLE_PAYMENT_INVOICES_SUCCESS';
export const DOWNLOAD_MULTIPLE_PAYMENT_INVOICES_FAIL =
  'DOWNLOAD_MULTIPLE_PAYMENT_INVOICES_FAIL';

export const RESET_PAYMENT_STORE = 'RESET_PAYMENT_STORE';

export const LOGOUT = 'LOGOUT';

export type PaymentActionTypes =
  | typeof FETCH_PAYMENT_LIST_START
  | typeof FETCH_PAYMENT_LIST_SUCCESS
  | typeof FETCH_PAYMENT_LIST_FAIL
  | typeof FETCH_USER_PAYMENT_LIST_START
  | typeof FETCH_USER_PAYMENT_LIST_SUCCESS
  | typeof FETCH_USER_PAYMENT_LIST_FAIL
  | typeof FETCH_PAYMENT_START
  | typeof FETCH_PAYMENT_SUCCESS
  | typeof FETCH_PAYMENT_FAIL
  | typeof CREATE_PAYMENT_START
  | typeof CREATE_PAYMENT_SUCCESS
  | typeof CREATE_PAYMENT_FAIL
  | typeof CREATE_COUPON_PAYMENT_START
  | typeof CREATE_COUPON_PAYMENT_SUCCESS
  | typeof CREATE_COUPON_PAYMENT_FAIL
  | typeof FETCH_USER_PAYMENTS_LIST_START
  | typeof FETCH_USER_PAYMENTS_LIST_SUCCESS
  | typeof FETCH_USER_PAYMENTS_LIST_FAIL
  | typeof FETCH_USER_SUBSCRIPTION_PAYMENTS_LIST_START
  | typeof FETCH_USER_SUBSCRIPTION_PAYMENTS_LIST_SUCCESS
  | typeof FETCH_USER_SUBSCRIPTION_PAYMENTS_LIST_FAIL
  | typeof DOWNLOAD_MULTIPLE_PAYMENT_INVOICES_START
  | typeof DOWNLOAD_MULTIPLE_PAYMENT_INVOICES_SUCCESS
  | typeof DOWNLOAD_MULTIPLE_PAYMENT_INVOICES_FAIL
  | typeof RESET_PAYMENT_STORE
  | typeof LOGOUT;
