import * as actionTypes from './actionTypes';
import { SessionActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { SelectedSession, Session } from '../../domain/Session';

export type SessionStateType = {
  sessions: Session[];
  sessionsLoading: boolean;
  sessionsError: HttpError;
  sessionCreateLoading: boolean;
  sessionCreateError: HttpError;
  sessionCreateSuccess: boolean;
  createdSession: Session | null;
  sessionUpdateLoading: boolean;
  sessionUpdateError: HttpError;
  sessionUpdateSuccess: boolean;
  sessionDeleteLoading: boolean;
  sessionDeleteError: HttpError;
  sessionDeleteSuccess: boolean;
  dayDeleteLoading: boolean;
  dayDeleteError: HttpError;
  dayDeleteSuccess: boolean;
  selectedTime: string | null;
  selectedSessions: SelectedSession[] | null;
  selectedSessionsLoading: boolean;
  selectedSessionsError: HttpError;
};

export type SessionActionType = SessionStateType & {
  type: SessionActionTypes;
  value: string;
};

export const initialState: SessionStateType = {
  sessions: [],
  sessionsLoading: false,
  sessionsError: null,
  sessionCreateLoading: false,
  sessionCreateError: null,
  sessionCreateSuccess: false,
  createdSession: null,
  sessionUpdateLoading: false,
  sessionUpdateError: null,
  sessionUpdateSuccess: false,
  sessionDeleteLoading: false,
  sessionDeleteError: null,
  sessionDeleteSuccess: false,
  dayDeleteSuccess: false,
  dayDeleteError: null,
  dayDeleteLoading: false,
  selectedTime: null,
  selectedSessions: null,
  selectedSessionsLoading: false,
  selectedSessionsError: null,
};

const fetchSessionsStart = (state: SessionStateType): SessionStateType => ({
  ...state,
  sessionsLoading: true,
});

const fetchSessionsSuccess = (
  state: SessionStateType,
  action: SessionActionType,
): SessionStateType => ({
  ...state,
  sessions: action.sessions,
  sessionsLoading: false,
  sessionsError: null,
  sessionDeleteSuccess: false,
  sessionUpdateSuccess: false,
  sessionCreateSuccess: false,
  dayDeleteSuccess: false,
  sessionCreateError: null,
  sessionUpdateError: null,
  sessionDeleteError: null,
  dayDeleteError: null,
});

const fetchSessionsFail = (
  state: SessionStateType,
  action: SessionActionType,
): SessionStateType => ({
  ...state,
  sessionsError: action.sessionsError,
  sessionsLoading: false,
});

const fetchSelectedSessionsStart = (
  state: SessionStateType,
): SessionStateType => ({
  ...state,
  selectedSessionsLoading: true,
});

const fetchSelectedSessionsSuccess = (
  state: SessionStateType,
  action: SessionActionType,
): SessionStateType => ({
  ...state,
  selectedSessions: action.selectedSessions,
  selectedSessionsLoading: false,
  selectedSessionsError: null,
});

const fetchSelectedSessionsFail = (
  state: SessionStateType,
  action: SessionActionType,
): SessionStateType => ({
  ...state,
  selectedSessionsError: action.selectedSessionsError,
  selectedSessionsLoading: false,
});

const createSessionStart = (state: SessionStateType): SessionStateType => ({
  ...state,
  sessionCreateLoading: true,
});

const createSessionSuccess = (
  state: SessionStateType,
  action: SessionActionType,
): SessionStateType => ({
  ...state,
  sessionCreateLoading: false,
  sessionCreateError: null,
  sessionCreateSuccess: true,
  createdSession: action.createdSession,
});

const createSessionFail = (
  state: SessionStateType,
  action: SessionActionType,
): SessionStateType => ({
  ...state,
  sessionCreateLoading: false,
  sessionCreateError: action.sessionCreateError,
});

const updateSessionStart = (state: SessionStateType): SessionStateType => ({
  ...state,
  sessionUpdateLoading: true,
});

const updateSessionSuccess = (state: SessionStateType): SessionStateType => ({
  ...state,
  sessionUpdateLoading: false,
  sessionUpdateError: null,
  sessionUpdateSuccess: true,
});

const updateSessionFail = (
  state: SessionStateType,
  action: SessionActionType,
): SessionStateType => ({
  ...state,
  sessionUpdateLoading: false,
  sessionUpdateError: action.sessionUpdateError,
});

const deleteSessionStart = (state: SessionStateType): SessionStateType => ({
  ...state,
  sessionDeleteLoading: true,
});

const deleteSessionSuccess = (state: SessionStateType): SessionStateType => ({
  ...state,
  sessionDeleteLoading: false,
  sessionDeleteError: null,
  sessionDeleteSuccess: true,
});

const deleteSessionFail = (
  state: SessionStateType,
  action: SessionActionType,
): SessionStateType => ({
  ...state,
  sessionDeleteLoading: false,
  sessionDeleteError: action.sessionDeleteError,
});

const deleteDayStart = (state: SessionStateType): SessionStateType => ({
  ...state,
  dayDeleteLoading: true,
});

const deleteDaySuccess = (state: SessionStateType): SessionStateType => ({
  ...state,
  dayDeleteLoading: false,
  dayDeleteError: null,
  dayDeleteSuccess: true,
});

const deleteDayFail = (
  state: SessionStateType,
  action: SessionActionType,
): SessionStateType => ({
  ...state,
  dayDeleteLoading: false,
  dayDeleteError: action.dayDeleteError,
});

const setTimeSelect = (
  state: SessionStateType,
  action: SessionActionType,
): SessionStateType => ({
  ...state,
  selectedTime: action.value,
});

const resetTimeSelectStore = (state: SessionStateType): SessionStateType => ({
  ...state,
  selectedTime: null,
});

const resetSessions = (state: SessionStateType): SessionStateType => ({
  ...state,
  sessions: [],
});

const resetValidatedSessions = (state: SessionStateType): SessionStateType => ({
  ...state,
  selectedSessions: [],
});

const resetCreatedSessionStore = (
  state: SessionStateType,
): SessionStateType => ({
  ...state,
  sessionCreateLoading: false,
  sessionCreateError: null,
  sessionCreateSuccess: false,
  createdSession: null,
});

const resetUpdatedSessionStore = (
  state: SessionStateType,
): SessionStateType => ({
  ...state,
  sessionUpdateLoading: false,
  sessionUpdateError: null,
  sessionUpdateSuccess: false,
});

const completelyResetSessionStore = (): SessionStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: SessionActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SESSIONS_START:
      return fetchSessionsStart(state);
    case actionTypes.FETCH_SESSIONS_SUCCESS:
      return fetchSessionsSuccess(state, action);
    case actionTypes.FETCH_SESSIONS_FAIL:
      return fetchSessionsFail(state, action);
    case actionTypes.CREATE_SESSION_START:
      return createSessionStart(state);
    case actionTypes.CREATE_SESSION_SUCCESS:
      return createSessionSuccess(state, action);
    case actionTypes.CREATE_SESSION_FAIL:
      return createSessionFail(state, action);
    case actionTypes.UPDATE_SESSION_START:
      return updateSessionStart(state);
    case actionTypes.UPDATE_SESSION_SUCCESS:
      return updateSessionSuccess(state);
    case actionTypes.UPDATE_SESSION_FAIL:
      return updateSessionFail(state, action);
    case actionTypes.DELETE_SESSION_START:
      return deleteSessionStart(state);
    case actionTypes.DELETE_SESSION_SUCCESS:
      return deleteSessionSuccess(state);
    case actionTypes.DELETE_SESSION_FAIL:
      return deleteSessionFail(state, action);
    case actionTypes.DELETE_DAY_START:
      return deleteDayStart(state);
    case actionTypes.DELETE_DAY_SUCCESS:
      return deleteDaySuccess(state);
    case actionTypes.DELETE_DAY_FAIL:
      return deleteDayFail(state, action);
    case actionTypes.SELECT_TIME:
      return setTimeSelect(state, action);
    case actionTypes.FETCH_SELECTED_SESSIONS_START:
      return fetchSelectedSessionsStart(state);
    case actionTypes.FETCH_SELECTED_SESSIONS_SUCCESS:
      return fetchSelectedSessionsSuccess(state, action);
    case actionTypes.FETCH_SELECTED_SESSIONS_FAIL:
      return fetchSelectedSessionsFail(state, action);
    case actionTypes.RESET_VALIDATED_SESSIONS:
      return resetValidatedSessions(state);
    case actionTypes.RESET_SELECT_TIME_STORE:
      return resetTimeSelectStore(state);
    case actionTypes.RESET_SESSIONS:
      return resetSessions(state);
    case actionTypes.RESET_CREATED_SESSION_STORE:
      return resetCreatedSessionStore(state);
    case actionTypes.RESET_UPDATED_SESSION_STORE:
      return resetUpdatedSessionStore(state);
    case actionTypes.LOGOUT:
      return completelyResetSessionStore();
    default:
      return state;
  }
};

export default reducer;
